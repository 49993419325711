<template>
  <div class="form-group">
    <b-input-group prepend="Discount Code" class="mt-3">
      <b-form-select id="discount_code" v-model="discountCode" :options="discountCodes" text-field="code" value-field="@id">
        <template #first>
          <b-form-select-option :value="null">None</b-form-select-option>
        </template>
      </b-form-select>
      <b-input-group-append>
        <b-button variant="outline-success" :disabled="!discountCode" @click="applyDiscountCode">Apply</b-button>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    inv: {
      type: Object,
      required: true
    }
  },
  name: 'InvoiceDiscountCode',
  data () {
    return {
      invoice: this.inv,
      discountCode: null
    }
  },
  computed: {
    ...mapGetters(['discountCodes'])
  },
  mounted () {
    this.$store.dispatch('getDiscountCodes', {
      business: this.currentBusiness.id,
      pagination: false,
      active: 1,
      'dateExpires[after]': new Date().toISOString().split('T')[0],
    })
  },
  watch: {
    inv: {
      handler (val) {
        this.invoice = val
      },
      deep: true
    }
  },
  methods: {
    async applyDiscountCode () {
      await this.$store.dispatch('putInvoice', {
        id: this.invoice.id,
        discountCode: this.discountCode
      })
      await this.recalculateInvoice(this.invoice.id)
      this.$emit('update:discountCode')
    }
  }
} 
</script>
