<template>
  <b-form 
    :id="formId"
    :ref="formId"
    method="POST"
    action="/an-charge-resp"
  >
    <input type="hidden" name="dataValue" id="dataValue" v-model="nonce.dataValue" />
    <input type="hidden" name="dataDescriptor" id="dataDescriptor" v-model="nonce.dataDescriptor" />
    <p class="float-left">Please click the Pay Now button to continue paying via Debit/Credit.</p>
    <b-button type="button" variant="success"
      class="AcceptUI float-right"
      data-billingAddressOptions='{"show":true, "required":false}' 
      :data-apiLoginID="apiLoginID" 
      :data-clientKey="clientKey"
      data-acceptUIFormBtnTxt="Submit" 
      data-acceptUIFormHeaderTxt="Card Information"
      data-paymentOptions='{"showCreditCard": true, "showBankAccount": false}' 
      data-responseHandler="ccResponseHandler"><i class="fa fa-credit-card"></i> Pay Now</b-button>
  </b-form>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { HTTP } from '@/services/http-common';
export default {
  name: 'PaymentFormAuthorize',
  props: {
    inv: {
      type: Object
    },
    discountCode: {
      type: Object
    },
    formId: {
      type: String
    },
    // paymentMethodId: {
    //   type: Number
    // }
  },
  head: {
    // script: [
    //   { src: `https://js.authorize.net/v3/AcceptUI.js` },
    // ]
  },
  // asyncData ({ store, route }) {
  //   return store.dispatch('business/getPaymentOptions', {
  //     business: store.state.business.business.id,
  //     active: 1,
  //     paymentOption: 1
  //   }).then(resp => {
  //     return {
  //       apiLoginID: resp[0].paymentOptionData.apiLoginId,
  //       clientKey: resp[0].paymentOptionData.apiPublicKey
  //     }
  //   })
  // },
  data () {
    return {
      paymentMethodId: null,
      apiLoginID: null,
      clientKey: null,
      testMode: false,
      hasBeenCalled: false,
      nonce: {
        dataDescriptor: '',
        dataValue: ''
      }
    }
  },
  computed: {
    // ...mapGetters({
    //   business: 'currentBusiness'
    // }),
  },
  methods: {
    ...mapActions(['putInvoice', 'getBusinessPaymentOptions']),
    ccResponseHandler (response) {
      // console.log(response)
      if (response.messages.resultCode === "Error") {
        var i = 0
        while (i < response.messages.message.length) {
          this.showError({ message: response.messages.message[i].text })
          i = i + 1
        }
        this.$emit('transaction-canceled', response.messages.message[0].text)
      } else {
        this.ccFormUpdate(response.opaqueData)
      }
    },
    async ccFormUpdate (opaqueData) {
      this.nonce.dataDescriptor = opaqueData.dataDescriptor
      this.nonce.dataValue = opaqueData.dataValue
      let data = {
          nonce: this.nonce,
          invoiceData: this.inv,
          businessId: this.currentBusiness.id,
          paymentOption: this.paymentMethodId
      }
      // this.$nuxt.$loading.start()
      let process = await HTTP.post('https://api.nzonesports.com/en/payment', data).then(resp => {
        if (resp.data.isSuccessful) {
          this.putInvoice({
            id: this.inv.id,
            paymentMethod: '/api/business_payment_options/' + this.paymentMethodId,
            transactionId: resp.data.transactionReference,
            datePaid: this.$moment().format('YYYY-MM-DD'),
          }).then(resp2 => {
            this.$emit('transaction-complete', resp2)
          })
        } else {
          this.$emit('transaction-failed', resp.data.message)
        }
      }, error => {
        console.error(error)
        this.$emit('transaction-failed', error)
        this.showError({ message: error })
      })
      // this.$nuxt.$loading.finish()
      return process
      // this.$refs[this.formId].submit()
    }
  },
  created () {
    this.getBusinessPaymentOptions({
      business: this.currentBusiness.id,
      active: 1,
      paymentOption: 1
    }).then(resp => {
      this.apiLoginID = resp[0].paymentOptionData.apiLoginId
      this.clientKey = resp[0].paymentOptionData.apiPublicKey
      this.paymentMethodId = resp[0].id

      if (!this.hasBeenCalled) {
        let accept = document.createElement("script")
        accept.setAttribute("src", "https://" + (this.testMode ? "jstest" : "js") + ".authorize.net/v3/AcceptUI.js")
        document.body.appendChild(accept)
        window.ccResponseHandler = this.ccResponseHandler
        this.hasBeenCalled = true
      }
    })
  }
}
</script>