<template>
  <div>
    <b-card v-if="inv" footer-tag="footer">
      <b-row>
        <b-col>
          <b-img src="https://nzonesports.com/images/logo-nzone_sports-dark-552×200.png" height="54" alt="" />
        </b-col>
        <b-col class="text-right">
          <h3 v-if="inv.transactionId" class="text-success">PAID</h3>
          <h3 v-else-if="inv.paymentMethod && inv.paymentMethod.id" class="text-warning">PENDING</h3>
          <h3 v-else class="text-warning">UNPAID</h3>
          <p>
            <span class="h4">Invoice #{{ inv.id }}</span><br />
            <!-- a class="h4" :href="'http://' + sitePublicDomain + '/' + inv.business.uriName + '/invoice?iid=' + inv.id" target="_blank" title="View Member Invoice">Invoice #{{ inv.id }}</a><br / -->
            Created: {{ $moment(inv.dateCreated).format('YYYY-MM-DD') }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p>
            N Zone Sports - {{ inv.business.areaName }}<br />
            {{ inv.business.address1 }}<br />
            {{ inv.business.city }} {{ inv.business.zipCode }}
          </p>
        </b-col>
        <b-col>
          <p class="text-right">
            {{ inv.member.fullName }}<br />
            {{ inv.member.address1 }} {{ inv.member.address2 }}<br />
            {{ inv.member.city }} {{ inv.member.state }} {{ inv.member.zipCode }}
          </p>
        </b-col>
      </b-row>

      <div class="bg-light border-top my-2 px-1">
        <!-- PAYMENT METHOD -->
        <b-row>
          <b-col>
            <h5 class="my-1">Payment Method</h5>
          </b-col>
          <b-col>
            <h5 class="my-1">Payment Date</h5>
          </b-col>
          <b-col>
            <h5 class="text-right my-1">Transaction ID</h5>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col>
          <p>
            <LiveEdit modalTitle="Payment Method" :value="inv.paymentMethod.id" :options="paymentOptions"
              @input="updateInv('paymentMethod', $event)" />
          </p>
          <!-- p v-else>
            None Selected
          </p -->
        </b-col>
        <b-col>
          <p>
            <LiveEdit modalTitle="Payment Date" input-type="date" :value="(inv.datePaid ? $moment(inv.datePaid).format('YYYY-MM-DD') : null)"
              @input="updateInv('datePaid', $event)" placeholder="Payment Date" />
          </p>
        </b-col>
        <b-col>
          <p v-if="inv.paymentMethod" class="text-right">
            <LiveEdit modalTitle="Transaction ID / Check #" :inline="false" :value="inv.transactionId"
              @input="updateInv('transactionId', $event)" placeholder="Transaction ID" />
          </p>
          <p v-else class="text-right">
            N/A
          </p>
        </b-col>
      </b-row>

      <div class="bg-light border-top my-2 px-1">
        <!-- INVOICE ITEMS -->
        <b-row>
          <b-col cols="6">
            <h5 class="my-1">Item</h5>
          </b-col>
          <b-col>
            <h5 class="text-center my-1">Cost</h5>
          </b-col>
          <b-col>
            <h5 class="text-center my-1">Qty</h5>
          </b-col>
          <b-col>
            <h5 class="text-right my-1">Total</h5>
          </b-col>
        </b-row>
      </div>
      <b-row v-for="(item, index) in inv.items" :key="index">
        <b-col cols="6">
          <h5 v-if="item.registration">
            <!-- b-btn size="sm" class="mr-2" variant="warning" v-b-tooltip.hover title="Remove Item"><i class="fa fa-remove"></i></b-btn -->
            <router-link :to="{ 
              name: 'eventRegistrationDetails',
              params: {
                rid: rid(item.registration)
              }
            }">{{ item.item }}</router-link>
          </h5>
          <h5 v-else>{{ item.item }} <a href="#" class="text-danger" @click.prevent="removeItem(item.id)">remove</a></h5>
        </b-col>
        <b-col>
          <h5 class="text-center">
            $
            <LiveEdit :modalTitle="item.item + ' Price'" :inline="false"
              :editMode="!inv.transactionId || inv.transactionId === tidFree" :value="item.price.toString()"
              @input="updateItem(index, 'price', $event)" />
          </h5>
        </b-col>
        <b-col>
          <h5 class="text-center">{{ item.quantity }}</h5>
        </b-col>
        <b-col>
          <h5 class="text-right">{{ item.price | toDollarAmnt }}</h5>
        </b-col>
      </b-row>

      <div v-if="inv.discountCode">
        <!-- DISCOUNT CODE -->
        <b-row>
          <b-col>
            <h5>Discount  <small>({{ inv.discountCode.code }}) <a href="#" class="text-danger" @click.prevent="removeDiscountCode">remove</a></small></h5>
          </b-col>
          <b-col>
            <h5 class="text-right text-danger">-{{ discountTotal | toDollarAmnt }}</h5>
          </b-col>
        </b-row>
      </div>

      <div v-if="inv.tax">
        <!-- TAX -->
        <b-row>
          <b-col>
            <h5>Tax</h5>
          </b-col>
          <b-col>
            <h5 class="text-right">{{ inv.tax | toDollarAmnt }}</h5>
          </b-col>
        </b-row>
      </div>
      
      <b-row v-if="inv && !inv.discountCode && ! inv.transactionId" class="mt-3">
        <!-- ADD DISCOUNT CODE -->
        <b-col cols="12" md="6" lg="4">
          <InvoiceDiscountCode :inv="inv" @update:discountCode="doGetInvoice" />
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <!-- TOTAL DUE -->
        <b-col>
          <h5 class="text-right">Total: {{ inv.amountDue | toDollarAmnt }}</h5>
        </b-col>
      </b-row>
      <BtnPrint />
    </b-card>
    <PaymentFormAuthorize v-if="inv && ! inv.transactionId" formId="paymentForm" :inv="inv"
      :paymentMethodId="inv.paymentMethod.id" @transaction-complete="onTransactionComplete"
      @transaction-failed="onTransactionFailed" @transaction-canceled="onTransactionCanceled" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import LiveEdit from '@/components/LiveEdit'
import PaymentFormAuthorize from '@/components/PaymentFormAuthorize'
import { constants } from '@/shared/constants'
import InvoiceDiscountCode from './~InvoiceDiscountCode'

export default {
  components: { LiveEdit, PaymentFormAuthorize, InvoiceDiscountCode },
  data () {
    return {
      inv: null,
      paymentOptions: []
    }
  },
  computed: {
    tidFree() {
      return constants.TID_FREE
    },
    paymentMethod() {
      return this.inv.paymentMethod ? this.inv.paymentMethod.paymentOption.label : 'N/A'
    },
    discountTotal() {
      let discountTotal = this.inv.discountCode ? this.inv.discountCode.amount : 0
      if (this.inv.discountCode && this.inv.discountCode.type === 'percent') {
        // calculate amount (taxable items only)
        let amountDue = 0
        for (let i = 0; i < this.inv.items.length; i++) {
          let item = this.inv.items[i]
          if (item.taxable) { // @XXX using taxable flag as a filter - should use a separate param
            amountDue += item.price
          }
        }
        discountTotal = (parseInt(this.inv.discountCode.amount) / 100) * amountDue
      }
      return discountTotal
    }
  },
  watch: {
    'inv.items': {
      handler() {},
      deep: true
    }
  },
  async mounted() {
    await this.$store.dispatch('getBusinessPaymentOptions', {
      business: this.currentBusiness.id
    }).then(resp => {
      for (let i in resp) {
        this.paymentOptions.push({
          text: resp[i].paymentOption.label,
          value: resp[i].id
        })
      }
      this.paymentOptions.push({
        text: 'None Selected',
        value: 0
      })
    })
    await this.doGetInvoice()
  },
  filters: {
    formatDiscount() {
      return '122'
    }
  },
  methods: {
    ...mapActions([
      'getInvoice',
      'putInvoice',
      'putInvoiceItem',
      'deleteInvoiceItem'
    ]),
    doGetInvoice() {
      if (this.$route.params.iid) {
        this.getInvoice({
          id: this.$route.params.iid
        }).then(resp => {
          if (!resp.paymentMethod) {
            resp.paymentMethod = {}
            resp.paymentMethod.id = 0
          }
          this.inv = resp
        })
      }
    },
    async removeDiscountCode() {
      await this.putInvoice({
        id: this.inv.id,
        discountCode: null
      })
      await this.recalculateInvoice(this.inv.id)
      this.doGetInvoice()
    },
    removeItem(id) {
      this.$bvModal.msgBoxConfirm('Are you sure you want to remove this item?', {
        title: 'Remove Item',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }).then(async value => {
        if (value) {
          await  this.deleteInvoiceItem(id)
          await this.recalculateInvoice(this.inv.id)
          this.doGetInvoice()
        }
      })
    },
    rid(registration) {
      if (! registration || ! registration['@id']) {
        return null
      }
      let segments = registration['@id'].split('/'),
        rid = segments[(segments.length - 1)]
      return rid
    },
    updateInv(prop, value) {
      let update = {
        id: this.inv.id
      }
      switch (prop) {
        case 'paymentMethod':
          if (value) { update[prop] = '/api/business_payment_options/' + value }
          else { update[prop] = null }
          break
        case 'transactionId':
          update[prop] = value
          if (value && !this.inv.datePaid) { update.datePaid = this.$moment().format() }
          else if (!value) { update.datePaid = null }
          break
        default: update[prop] = value
      }
      this.putInvoice(update).then(resp => {
        if (!resp.data.paymentMethod) {
          resp.data.paymentMethod = {}
          resp.data.paymentMethod.id = 0
        }
        this.inv = resp.data
      })
    },
    async updateItem(index, prop, value) {
      this.inv.items[index][prop] = parseFloat(value || 0)
      let item = { ...this.inv.items[index] }
      item.registration = item.registration ? item.registration['@id'] : null
      await this.putInvoiceItem(item).then(resp => {
        this.inv.items[index] = resp.data
      })
      await this.recalculateInvoice(this.inv.id)
      this.doGetInvoice()
    },
    onTransactionComplete() {
      this.doGetInvoice()
    },
    onTransactionFailed(error) { },
    onTransactionCanceled() { },
  }
}
</script>
